<template>
 <div>
    <v-row  no-gutters>
      <v-carousel
      v-if="mobile"
    cycle
    height="150"
    interval="6000"
    hide-delimiter-background
    show-arrows-on-hover
  >
  <v-carousel-item>
    <div class="hero1 text-center pa-2">
      <span class="text-white ev-date">Latest Winners</span>
      <div v-for="(item,index) in WinnersGroup" :key="index">
      <div class="text-warning potato-text lg-font animate__animated animate__bounceInLeft" >
          {{item.fullname}}
      </div>
    </div>
    </div>
  </v-carousel-item>
  <v-carousel-item>
    <div class="hero2">
        <div class="text-center" >
          <div style="padding-top: 40px"></div>
          <a   @click="$router.push('/numbers').catch(err => {})"  class="text-white btn potato-text lg-font animate__animated animate__bounceInLeft">PLAY NOW! </a>
        </div>
        
    </div>
  </v-carousel-item>
  <v-carousel-item>
    <div class="hero3"></div>
  </v-carousel-item>
</v-carousel>
       
<v-col cols="12" sm="6" class="pa-1" v-for="(item, index) in CLOSED" :key="index">
            
            <va-side-card 
              color="rescard-side"
              icon="mdi-billiard"
              :data="item"
              sup=""
            />
        </v-col>
          <v-col cols="12" sm="6" class="pa-1" v-for="(item, index) in OPEN" :key="index">
            
                  <va-side-card 
                    color="rescard-side"
                    icon="mdi-billiard"
                    :data="item"
                    sup=""
                  />
              </v-col>
              <v-col cols="12" sm="6" class="pa-1" v-for="(item, index) in UPCOMING" :key="index">
            
            <va-side-card 
              color="rescard-side"
              icon="mdi-billiard"
              :data="item"
              sup=""
            />
        </v-col>
           
              <v-col cols="12"   sm="12" class="pa-1">
                  <v-card class="rescard">
                      <v-card-text class="text-center">
                        <!-- <div class="text-h6 text-gold">MAGIC </div> -->
                        <div class="mt-5">
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">N</span></v-btn>
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">U</span></v-btn>
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">M</span></v-btn>
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">B</span></v-btn>
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">E</span></v-btn>
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">R</span></v-btn>
                        <v-btn fab x-small class="ma-1 _numbtn"><span class="text-h6" style="color: #fff;">S</span></v-btn>
                        </div>
                      </v-card-text>
                      <v-card-actions>
               <v-btn color="tertiary" class="btn-grad-primary" dark @click="$router.push('/numbers').catch(err => {})" block> PLAY</v-btn>
             </v-card-actions>
                  </v-card>

              </v-col>
       
          <v-col cols="12"  class="pa-2 mt-5">
            <v-btn  small @click="how=true" block><v-icon  left color="info">mdi-account-question</v-icon> HOW TO PLAY</v-btn>
        </v-col>

    </v-row>
    
    <va-howto :show="how" @DialogEvent="hEvent"/>
  </div>
</template>
<script>
//import { API_MIXINS } from '@/mixins/api_mixins.js'
import {
  mapMutations
} from 'vuex'
  export default {
   // mixins: [API_MIXINS],
    data: () => ({
      winners:[],
      mobile: false,
      watch: false,
      DRAWINTERVAL: null,
      next_draw:"",
      how:false,
      result: {},
      events:[],
      system: {},
    }),

     created(){
         this.setDrawer(false)
       this.setLoggedIn(true)
       this.checkScreenSize()
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          this.PING()
        this.setData()
          this.SYSTEM_UPDATE()
          this.getEvents()
         
        }
        
    },
    beforeDestroy(){
       if(this.CHECKINTERVAL != null) { clearInterval(this.CHECKINTERVAL)}
       return true
    },
    computed:{
     
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
      RESULTS() {
        return this.$store.state.results.length>0?this.$store.state.results:[]
      },
       RESULT2D() {
         return this.GET_RESULT("2D")
      },
      WinnersGroup(){
          return this.getRandomElements(this.winners,3)
        },
      item_count() {
        return this.$store.state.tickets.length.toString()
      },
      item_count_total() {
          var count = this.$store.state.tickets.reduce((res,item)=>res += this.$IsNum(item.amount), 0)
          return count.toString()
      },
      CLOSED() {
        var events = this.events.reduce((res,item)=>{
          if(item.status == 'Closed') {
            res.push(item)
          }
          return res
        }, [])
        return events
      },
      OPEN() {
        var events = this.events.reduce((res,item)=>{
          if(item.status == 'Open') {
            res.push(item)
          }
          return res
        }, [])
        return events
      },
       UPCOMING() {
        var events = this.events.reduce((res,item)=>{
          if(item.status == 'Upcoming') {
            res.push(item)
          }
          return res
        }, [])
        return events
      }
    
    },
    methods: {
      ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading', 'setOverlayLoadingText', 'setOpenMachine','setWatchDraw', 'setLiveDrawStatus']),
        checkScreenSize() {
            if (window.matchMedia("(max-width: 768px)").matches) {
              this.mobile = true
            } else {
              this.mobile = false
            }
      },
      getEvents() { 
        this.$http.get("event/open").then(response => {
            response.data.items != null?this.events = response.data.items:this.events =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
     getWinners() { 
        this.$http.get("get_latest_winners").then(response => {
            response.data.items != null?this.winners = response.data.items:this.winners =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
      hEvent(){
        this.how= false
      },
      GET_LABEL(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
              
                data =  this.GAMENAME(results[i].game) + " - "  +  results[i].time  + " DRAW"
                break
            } 
          }
       
          return data
      },
       GET_RESULT(game) {
          var data
          var results =  this.$store.state.results.length>0?this.$store.state.results:[]
          for(var i=0; i<results.length;i++) {
            if(results[i].game == game) {
               var nums = results[i].combination.split(" - ")
                results[i].nums = nums
                data =  results[i]
                break
            } 
          }
       
          return data
      },
      get_system_update() {
            //this.setRequiredUpdate(false)
            this.$http.get("system_update").then(response => {
              if(response.data.status) {
                 this.system = response.data.system
              }
               }).catch(e => {
                console.log(e.data)
            })
        },
       
        getRandomElements(arr, count) {
            const shuffled = arr.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        },
        setData() {
          this.getWinners()
          this.CHECK_BALANCE(this.user.id)
          var param = {}
          param.account = this.user.id
          param.method = "per_account"
          param.from_date =  this.$moment().format("YYYY-MM-DD")
          this.GET_TICKETS(param)
          this.GET_RESULTS(param)
        
          setTimeout(()=>{this.get_system_update()}, 800)
      }
    },
  }
</script>